<template>
	<div class="app-container">
		<el-card class="elCard">
			<el-form :inline="true" ref="searchForm" :rules="rules" :model="searchForm" size="small" label-width="100px">
				<el-form-item label="旧密码：" prop="pass1">
					<el-input type="password" v-model="searchForm.pass1" maxlength='18' placeholder="请输入旧密码"></el-input>
				</el-form-item>
				<el-form-item label="新密码：" prop="pass">
					<el-input v-model="searchForm.pass" placeholder="请输入新密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码：" prop="checkPass">
					<el-input v-model="searchForm.checkPass" placeholder="请输入确认密码"></el-input>
				</el-form-item>
				<el-form-item style="float: right;">
					<el-button @click="dialogFormVisible = false">取 消</el-button>
					<el-button type="primary" @click="dialogConfirm('searchForm')">确 定</el-button>
				</el-form-item>
			</el-form>
		</el-card>
	</div>
</template>

<script>
	export default {
		data() {
			let validatePass = (rule, value, callback) => {
				if (value === '') {
				  callback(new Error('请输入密码'));
				} else {
				  if (this.searchForm.checkPass !== '') {
					this.$refs.searchForm.validateField('checkPass');
				  }
				  callback();
				}
			};
			let validatePass2 = (rule, value, callback) => {
				if (value === '') {
				  callback(new Error('请再次输入密码'));
				} else if (value !== this.searchForm.pass) {
				  callback(new Error('两次输入密码不一致!'));
				} else {
				  callback();
				}
			};
			return {
				searchForm: {
					pass1: '',
					pass: '',
					checkPass: ''
				},
				rules: {
					pass1: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
					pass: [{ required: true, validator: validatePass, trigger: 'blur' }],
					checkPass: [{ required: true, validator: validatePass2, trigger: 'blur' }],
				}
			}
		},
		components: {
			
		},
		created(){
			
		},
		methods:{
			dialogConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data = {
							oldPassword: this.searchForm.pass1,
							newPassword: this.searchForm.checkPass
						}
						this.$confirm('是否提交数据', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.$comjs.ajax.postAjax('/fa/system/change/pwd', data, this, res => {
								this.dialogFormVisible = false;
								this.$refs[formName].resetFields();
								this.$message({
									message: '修改成功！',
									type: 'success',
									duration: 1000
								});
							});
						}).catch(() => {
							
						});
					}
				});
			},
		},
		computed:{
			
		}
	}
</script>

<style lang="scss" scoped>
	.elCard {
		width: 350px;
		margin: 0 auto;
	}
</style>

